<template>
  <!--Container-->
  <div class="container w-1/3 md:w-1/3 xl:1/3 mx-auto px-2 mt-20">
    <div class="mb-4">
      <!-- component -->
      <form method="post" @submit.prevent="login">
        <div
          class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col"
        >
          <h1
            class="flex items-center justify-center font-bold break-normal text-indigo-500 px-2 pb-8 text-xl md:text-xl"
          >
            Admin Panel
          </h1>
          <div class="mb-4">
            <label
              class="block text-grey-darker text-sm font-bold mb-2"
              for="username"
            >
              Username
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="username"
              type="text"
              placeholder="Username"
              v-model="username"
            />
            <p class="text-red text-xs italic" v-if="error && !username">
              Please enter a username.
            </p>
          </div>
          <div class="mb-6">
            <label
              class="block text-grey-darker text-sm font-bold mb-2"
              for="password"
            >
              Password
            </label>
            <input
              class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
              id="password"
              type="password"
              placeholder="***********"
              autocomplete="off"
              v-model="password"
            />
            <p class="text-red text-xs italic" v-if="error && !password">
              Please enter a password.
            </p>
          </div>
          <div class="flex items-center justify-between">
            <button
              v-if="!loading"
              class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Sign In
            </button>
            <button
              v-if="loading"
              class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
              type="submit"
              disabled
            >
              Sign In...
            </button>
            <!-- <a class="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
			        Forgot Password?
			      </a> -->
          </div>
        </div>
      </form>
    </div>
  </div>
  <!--/container-->
</template>

<script>
import router from "../../router";

export default {
  name: "Login",
  data() {
    return {
      sidebarOpen: true,
      username: "",
      password: "",
      error: false,
      loading: false
    };
  },
  methods: {
    login() {
      this.error = false;
      if (!this.username) {
        this.error = true;
        return;
      }
      if (!this.password) {
        this.error = true;
        return;
      }
      this.loading = true;
      this.$axios
        .post(`${this.$api}/auth/login`, {
          username: this.username,
          password: this.password
        })
        .then(res => {
          // console.log(res);
          this.loading = false;
          if (res.data.success) {
            const token = res.data.token;
            const user = res.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user-role", user.role);
            localStorage.setItem("user-id", user.id);
            this.$cookies.set("user", JSON.stringify(user));
            this.$axios.defaults.headers.common["Authorization"] = token;
            router.push({ path: "/admin/dashboard" });
          } else {
            alert(res.data.message);
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          alert("Try again later");
        });
    }
  }
};
</script>
